import React, { useCallback } from 'react'
import styled from 'styled-components';

export default function HeaderBlock({ header, level }) {
    const renderHeader = useCallback((level) => {
        switch (level) {
            case 6:
                return <Header6 dangerouslySetInnerHTML={{__html: header }} />
            case 5:
                return <Header5 dangerouslySetInnerHTML={{__html: header }} />
            case 4:
                return <Header4 dangerouslySetInnerHTML={{__html: header }} />
            case 3:
                return <Header3 dangerouslySetInnerHTML={{__html: header }} />
            case 2:
                return <Header2 dangerouslySetInnerHTML={{__html: header }} />
            case 1:
                return <Header1 dangerouslySetInnerHTML={{__html: header }} />
            default:
                return <Header3 dangerouslySetInnerHTML={{__html: header }} />
                
        }
    }, [header])
    return (
        <Container>
            {renderHeader(level)}
        </Container>
    )
}
const Container = styled.div`
    margin-top: 20px;
    opacity: 0.9;
    font-family: "Haas";
    font-weight: 500;
`;
const Header1 = styled.div`
    font-size: 55px;
    line-height: 1.1;
    font-weight: 500 !important;

    h1 {
        font-weight: 500 !important;

    }

    @media (max-width: 550px) {
        font-size: 50px;
    }
`;
const Header2 = styled.div`
    font-size: 42px;
    line-height: 1.2;
    font-weight: 500 !important;

    h2 {
        font-weight: 500 !important;

    }
    
    @media (max-width: 550px) {
        font-size: 37px;
    }
`;
const Header3 = styled.div`
    font-size: 31px;
    line-height: 1.3;
    font-weight: 500 !important;

    h3 {
        font-weight: 500 !important;

    }
`;
const Header4 = styled.div`
    font-size: 24px;
    line-height: 1.2;
    font-weight: 500 !important;
    h4 {
        font-weight: 500 !important;

    }
`;
const Header5 = styled.div`
    font-size: 18px;
    line-height: 1.2;
    font-weight: 500 !important;

    h5 {
        font-weight: 500 !important;

    }
`;
const Header6 = styled.div`
    font-size: 16px;
    line-height: 1.2;
    font-weight: 500 !important;

    h6 {
        font-weight: 500 !important;

    }
`;