import React from 'react'
import styled from 'styled-components';

export default function ImageBlock({ caption, image }) {
    return (
        <Container>
            <Image src={image} caption={caption} />
            {caption && <Caption dangerouslySetInnerHTML={{__html: caption}} />}
        </Container>
    )
}
const Container = styled.div`
    margin: 20px 0;
`;
const Image = styled.img`
    margin-bottom: ${props => props.caption ? '10px' : 0};
    width: 100%;
`;
const Caption = styled.div`
    text-align: center;
    opacity: 0.6;
    font-size: 15px;
`;