import React from 'react'
import styled from 'styled-components';
import { colors } from '../../styles/colors';

export default function ListBlock({ style, items }) {
    switch (style) {
        case 'ordered':
            return (
                <Container>
                    <OrderedList>
                        {items.map((item, index) => (
                            <Item as="li" key={index} dangerouslySetInnerHTML={{__html: item}} />
                        ))}
                    </OrderedList>

                </Container>
            )
        case 'unordered':
            return (
                <Container>
                    <UnorderedList>
                        {items.map((item, index) => (
                            <Item as="li" key={index} dangerouslySetInnerHTML={{__html: item}} />
                        ))}
                    </UnorderedList>
                </Container>
            )
    
        default:
            break;
    }
   
}
const Container = styled.div`
    margin-top: 20px;
`;
const OrderedList = styled.ol`
    list-style: decimal;
    padding-left: 30px;
`;
const UnorderedList = styled.ul`
    list-style: square;
    padding-left: 30px;
`;
const Item = styled.div`
    margin-bottom: 10px;
    font-size: 19px;
    line-height: 1.4;
    opacity: 0.9;

    @media (max-width: 550px) {
        font-size: 16px;
    }

`;