import React from 'react'
import { Flex } from '../../layout/reusables';
import { Heading } from '../../styles/typography';
import OriginalContentGroup from './original-content-group';

export default function MoreOriginalContent({ name, logo, originalContentData }) {
    return (
        <Flex direction="column" align="center">
            <Heading>More from {name}</Heading>
            <OriginalContentGroup name={name} logo={logo} originalContentData={originalContentData.map(el => el.node)} />
        </Flex>
    )
}