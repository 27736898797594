import React from 'react'
import { InlineShareButtons } from 'sharethis-reactjs';
import placeholder from '../images/logo-placeholder.png';

export default function SocialShareButtons({ title, headerImage = placeholder }) {
    return (
        <InlineShareButtons
            config={{
                alignment: 'center',  // alignment of buttons (left, center, right)
                color: 'social',      // set the color of buttons (social, white)
                enabled: true,        // show/hide buttons (true, false)
                font_size: 16,        // font size for the buttons
                labels: 'cta',        // button labels (cta, counts, null)
                language: 'en',       // which language to use (see LANGUAGES)
                networks: [           // which networks to include (see SHARING NETWORKS)
                'whatsapp',
                'linkedin',
                'messenger',
                'facebook',
                'twitter'
                ],
                padding: 12,          // padding within buttons (INTEGER)
                radius: 4,            // the corner radius on each button (INTEGER)
                show_total: true,
                size: 40,             // the size of each button (INTEGER)
    
                // OPTIONAL PARAMETERS
                // url: 'https://www.sharethis.com', // (defaults to current url)
                image: headerImage,  // (defaults to og:image or twitter:image)
                description: title,       // (defaults to og:description or twitter:description)
                // title: 'custom title',            // (defaults to og:title or twitter:title)
                // username: 'custom twitter handle' // (only for twitter sharing)
            }}
        />
    )
}