import React from 'react'
import styled from 'styled-components';

export default function QuoteBlock({ caption, quote }) {
    return (
        <Container>
            <Quote dangerouslySetInnerHTML={{__html: quote}} caption={caption} />
            {caption && <Caption dangerouslySetInnerHTML={{__html: caption}} />}
        </Container>
    )
}
const Container = styled.div`
    margin-top: 50px;
    text-align: center;
`;
const Quote = styled.div`
    margin-bottom: ${props => props.caption ? '10px' : 0};
    font-weight: 400;
    font-style: italic;
    font-size: 28px;
    line-height: 1.5;
`;
const Caption = styled.div`
    text-align: center;
    opacity: 0.6;
    font-size: 15px;
`;