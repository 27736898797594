import React from 'react'
import styled from 'styled-components';
import { colors } from '../../styles/colors';

export default function ParagraphBlock({ paragraph }) {
    return (
        <Container>
            {paragraph && <Paragraph dangerouslySetInnerHTML={{__html: paragraph}} />}
        </Container>
    )
}
const Container = styled.div`
    margin-top: 20px;
`;

const Paragraph = styled.div`
    text-align: left;
    font-size: 20px;
    line-height: 1.5;
    opacity: 0.9;
    font-family: "Haas";
    font-weight: 400;

    a {
        text-decoration-color: ${colors.yellow};
        text-decoration-thickness: 3px;
        color: black;
        font-weight: 500;
    }
    b {
        font-weight: 500;
    }

    @media (max-width: 550px) {
        font-size: 16px;
    }
`;