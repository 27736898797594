import { graphql, Link } from "gatsby"
import React, { useCallback, useRef } from "react"
import styled, { keyframes } from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ContentWrapper, Flex, Wrapper } from "../layout/reusables"
import Fade from "react-reveal/Fade"
import { TiArrowUp } from "react-icons/ti"
import { colors } from "../styles/colors"
import { useWindowScroll } from "react-use"
import ImageBlock from "../components/article/image-block"
import ParagraphBlock from "../components/article/paragraph-block"
import HeaderBlock from "../components/article/header-block"
import QuoteBlock from "../components/article/quote-block"
import SocialShareButtons from "../components/social-share-buttons"
import MoreOriginalContent from "../components/channel/more-original-content"
import { _sluggedName } from "../../utils/utils"
import ListBlock from "../components/article/list-block"

const ProfileBlock = ({ createdAt, image, name }) => {
    return (
        <Link style={{color: 'inherit', textDecoration: 'none'}} to={`/${_sluggedName(name)}`}>
            <Flex style={{borderBottom: '1px solid #eee', padding: '20px 0', width: '100%'}} direction="column" align="center" justify="space-between">
                <Flex direction="row" align="center" justify="flex-start">
                    <img alt={`${name}-logo`} src={image} style={{objectFit: 'cover', border: '2px solid yellow', marginRight: 10, width: 40, borderRadius: 20, height: 40}} />
                    <p fontSize={18}>{name}</p>
                </Flex>
            </Flex>
        </Link>
    )
}

export default function ArticleTemplate({ data, pageContext }) {

    const { articleData } = data
    const { title, blocks, createdAt, headerImage } = articleData
    const { allOriginalContent, collaborator } = pageContext
    const { name, logo } = collaborator.node

    const excerpt = blocks.filter(block => block.type === "paragraph")

    const titleRef = useRef()

    const { y } = useWindowScroll()
    const scrollTopVisible = y > 200

    const handleScrollToTop = () => {
        titleRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
        })
    }

    const renderBlockItems = useCallback((block, index) => {
        const { data, type } = block

        console.log(type)
        console.log(data)

        switch (type) {
            case "image":
                return <ImageBlock key={index} caption={data.caption} image={data.file.url}/>
            case "paragraph":
                return <ParagraphBlock key={index} paragraph={data.text}/>
            case "header":
                return <HeaderBlock key={index} header={data.text} level={data.level} />
            case "quote":
                return <QuoteBlock key={index} quote={data.text} caption={data.caption} />
            case "list":
                return <ListBlock key={index} style={data.style} items={data.items} />
            default:
                break;
        }
    }, [])

    return (
        <Layout dark>
            <SEO title={title} description={`${excerpt[0]?.data?.text}`} />
            <Wrapper>
                <ContentWrapper style={{ padding: "20vh 20px" }}>
                    <Flex direction="column" align="center">
                        <ArticleTextWrapper style={{marginBottom: '5vh'}} ref={titleRef}>
                            <Title>{title}</Title>
                            <ProfileBlock createdAt={createdAt} image={logo} name={name} />
                        </ArticleTextWrapper>
                        {headerImage &&
                            <HeaderContainer>
                                <HeaderImage src={headerImage} />
                            </HeaderContainer>
                        }
                        <ArticleTextWrapper style={{marginBottom: 50}}>
                            <ArticleContainer>
                                {
                                    blocks.map((el, index) => (
                                        renderBlockItems(el, index)
                                    ))
                                }
                            </ArticleContainer>
                            <SocialShareButtons title={`${title} | Read it on PHEAL now`} headerImage={headerImage} />
                        </ArticleTextWrapper>
                        <MoreOriginalContent
                            logo={logo}
                            name={name}
                            originalContentData={allOriginalContent}  
                        />
                    </Flex>
                </ContentWrapper>
                {scrollTopVisible && (
                    <Container onClick={() => handleScrollToTop()}>
                        <TiArrowUp
                            style={{ marginBottom: 0 }}
                            color={colors.yellow}
                            size={34}
                        />
                    </Container>
                )}
            </Wrapper>
        </Layout>
    )
}

export const articleQuery = graphql`
  query($id: String!) {
    articleData: originalContent(id: { eq: $id }) {
        id
        title
        headerImage
        collaboratorId
        blocks {
          data {
            withBorder
            withBackground
            text
            style
            stretched
            level
            items
            file {
              url
            }
            caption
            alignment
          }
          type
        }
        createdAt(fromNow: true)
    }
  }
`

const enter = keyframes`
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`
const Container = styled.div`
    width: 40px;
    height: 40px;
    position: fixed;
    z-index: 98;
    bottom: 30px;
    right: 30px;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    padding: 10px;
    cursor: pointer;
    opacity: 0;
    transform: translateY(10px);

    animation: ${enter} 0.4s ease-out forwards;
    @media (max-width: 500px) {
        bottom: 20px;
        right: 10px;
    }
`

const HeaderContainer = styled.div`
    width: 100%;
    /* max-height: 50vh; */
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5vh;
`
const HeaderImage = styled.img`
    width: 100%;
`;

const Title = styled.h1`
    font-size: clamp(28px, 5vw, 45px);
    line-height: 1.3;
    font-weight: 500;
    text-align: left;
    color: black;
    text-align: center;
    font-family: 'Quattrocento';
    font-weight: 400;
`
const ArticleContainer = styled.div`
    width: 100%;
    margin-bottom: 50px;
`
const ArticleTextWrapper = styled.div`
    max-width: 680px;
`;